import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import IBBanner from '../../assets/dashboard/ib_banner.png'
import CheckMark from '../../assets/dashboard/checkmark.svg'
import Barron from '../../assets/dashboard/barron.png'
import Investopedia from '../../assets/dashboard/investopedia.png'
import BrokerChooser from '../../assets/dashboard/brokerchooser.png'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import PhoneInput from 'react-phone-input-2'
import '../../../src/scss/components/IBStatusScreen.scss'
import '../../scss/components/PrefinraQuestions.scss'
import Endpoint from '../Endpoint'
import Axios from 'axios'

const yesOrNo = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

export default function IBMigrationPopup(props) {
  const [dialogScreen, setDialogScreen] = useState(
    props?.userInfo?.prefinra_questionnaire?.migration_eligible === 'FALSE'
      ? 'denied'
      : 'main'
  )
  const [userForm, setUserForm] = useState({})
  const [finraErrorMessage, setFinraErrorMessage] = useState('')

  const handleOnboardingPress = () => {
    if (props?.userInfo?.prefinra_questionnaire?.migration_eligible === 'TRUE')
      props?.setShowIBQuestionnaire()
    else setDialogScreen('finra')
  }

  //  const validateForm = () => {
  //    let {
  //      first_name,
  //      last_name,
  //      birthdayDay,
  //      birthdayMonth,
  //      birthdayYear,
  //      phone_no,
  //      ustaxpayer,
  //    } = userForm

  //    // if (userInfo?.phone && !phone_no) phone_no = userInfo?.phone

  //    // step 1 form validateion for empty fields
  //    if (
  //      !first_name ||
  //      first_name?.trim() === '' ||
  //      !last_name ||
  //      last_name?.trim() === '' ||
  //      !birthdayDay ||
  //      !birthdayMonth ||
  //      !birthdayYear ||
  //      !phone_no ||
  //      !ustaxpayer
  //    ) {
  //      toastErrorMsg.current.show({
  //        severity: 'error',
  //        summary: 'Incompleted',
  //        detail: 'Please make sure all required fields are filled',
  //        life: 3000,
  //      })
  //      return false
  //    }

  //    // not age 18 or older
  //    if (!isAtLeast18YearsOld(birthdayYear, birthdayMonth, birthdayDay)) {
  //      toastErrorMsg.current.show({
  //        severity: 'error',
  //        summary: 'Birthday Invalid',
  //        detail: 'You need to be at least 18 years old',
  //        life: 3000,
  //      })
  //      return false
  //    }

  //    return true
  //  }

  const submitFinraOpenIBQuestionnaire = async () => {
    let body = {
      first_name: userForm?.first_name,
      last_name: userForm?.last_name,
      // phone_number: props?.userInfo?.phone
      //   ? props?.userInfo?.phone
      //   : `+${userForm?.phone_no?.replace(/\D/g, '')}`,
      phone_number: `${userForm?.phone_no?.replace(/\D/g, '')}`,
      smart_investing: 1,
      birth_day: userForm?.birthdayDay?.toString(),
      birth_month: userForm?.birthdayMonth?.toString(),
      birth_year: userForm?.birthdayYear?.toString(),
      ustaxpayer: userForm?.ustaxpayer === 'yes' ? true : false,
    }
    try {
      let res = await Axios.post(`${Endpoint}/mobile/prefinra-data/add`, body, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      console.log('[api response] - prefinra-data/add ', res?.data)
      if (res?.data?.status) {
        setFinraErrorMessage('')
        props?.setShowIBQuestionnaire()
      } else {
        console.log('error in submitting finra')
        setFinraErrorMessage(
          'There was an error with saving this data. Please try again'
        )
      }
    } catch (error) {
      console.log(error)
      setFinraErrorMessage(
        'There was an error with saving this data. Please try again'
      )
    }
  }

  const handleUserFormInputChange = (event) => {
    let { name, value } = event.target

    value = value?.replace(/[^A-Za-z0-9\s]/g, '')

    setUserForm((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const parseBirthday = (birthday) => {
    const date = new Date(birthday)
    handleBirthdayInputChange('birthday', birthday)
    handleBirthdayInputChange('birthdayDay', date.getDate())
    handleBirthdayInputChange('birthdayMonth', date.getMonth() + 1)
    handleBirthdayInputChange('birthdayYear', date.getFullYear())
  }
  const handleBirthdayInputChange = (name, value) => {
    setUserForm((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    )
  }

  // year template for the prime react ui calander
  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className='p-ml-2'
        style={{ lineHeight: 1 }}
      />
    )
  }

  const handleUsTaxPayerChange = (event) => {
    console.log('event', event)
    setUserForm((prevData) => ({
      ...prevData,
      ustaxpayer: event,
    }))
  }

  const renderDialogScreen = () => {
    switch (dialogScreen) {
      case 'denied':
        return (
          <div className='ib-migration-container user-info-container'>
            <p className='main-title'>
              ACTION REQUIRED: <br></br>
              <span style={{ color: 'red' }}>
                Your accounts with DriveWealth is in liquidation state.
              </span>
            </p>
            <p>
              Investall can no longer service your DriveWealth accounts due to
              termination of relationship.
            </p>
            <p>
              Please follow these steps to LIQUIDIATE and WITHDRAW your assets
              at your earliest convenience.
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Step 1</span> Liquidate any
              positions in your ROBO, Self Trader and AI Trader accounts.
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Step 2</span> Transfer all
              liquidated cash from your accounts to your primary bank. Thank you
              for your understanding and continued support.
            </p>
          </div>
        )
      case 'finra':
        return (
          <div className='ib-migration-container user-info-container'>
            <div className='input-container'>
              <label htmlFor='first_name'>What's your name?</label>
              <InputText
                id='first_name'
                name='first_name'
                value={userForm?.first_name}
                placeholder='First name '
                onChange={handleUserFormInputChange}
                style={{ marginBottom: '1rem' }}
              />
              <InputText
                id='last_name'
                name='last_name'
                placeholder='Last name'
                value={userForm?.last_name}
                onChange={handleUserFormInputChange}
              />
            </div>

            <div className='input-container'>
              <label>What is your date of birth?</label>
              <Calendar
                value={userForm?.birthday}
                onChange={(e) => parseBirthday(e.target.value)}
                dateFormat='mm/dd/yy'
                name='birthday'
                showIcon
                icon='pi pi-calendar'
                placeholder='birthday'
                monthNavigator={true}
                yearNavigator={true}
                monthNavigatorTemplate={monthNavigatorTemplate}
                yearNavigatorTemplate={yearNavigatorTemplate}
                yearRange={`1900:${new Date().getFullYear()}`}
                className='calendar-input'
              ></Calendar>
            </div>

            {/* {!props?.userInfo?.phone && ( */}
            <div className='input-container'>
              <label htmlFor='phone'>What is your phone number</label>

              <PhoneInput
                className='phone-input'
                id='phone'
                // placeholder='Phone number'
                name='phone_no'
                value={userForm?.phone_no}
                onChange={(e) => {
                  setUserForm((prevData) => ({
                    ...prevData,
                    ['phone_no']: e,
                  }))
                }}
                // countryCodeEditable={false}
                country='us'
                // enableSearch={true}
              />
            </div>
            {/* )} */}

            <div className='input-container'>
              <label>Are you a US-Tax Payer?</label>
              <Dropdown
                value={userForm?.ustaxpayer}
                options={yesOrNo}
                onChange={(e) => handleUsTaxPayerChange(e.value)}
                style={{ lineHeight: 1 }}
              />
            </div>
            {finraErrorMessage}
            <button
              className='onboarding-btn'
              onClick={() => submitFinraOpenIBQuestionnaire()}
            >
              Submit
            </button>
          </div>
        )
      case 'main':
      default:
        return (
          <div className='ib-migration-container'>
            <p className='main-title'>
              Please open a new brokerage account to continue using Investall.
            </p>
            <div>
              <p>
                Investall can no longer service your DriveWealth accounts due to
                termination of relationship.
              </p>
              <p>
                We’ve partnered with a new brokerage provider called Interactive
                Brokers that will allow you to continue taking advantage of the
                great features from Investall.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                Please set up your accounts with Interactive Brokers below:{' '}
              </p>
            </div>
            <button
              className='onboarding-btn'
              onClick={() => handleOnboardingPress()}
            >
              Start Onboarding
            </button>
            <p className='title-text'>
              Benefits of opening an Interactive Brokers account.
            </p>
            <img className='ib-logo' alt='' src={IBBanner} />
            <p className='title-text'>Get 4.83% on your cash</p>
            <div className='checkmark-content'>
              <img className='checkmark-icon' alt='check' src={CheckMark} />
              <p className='small-text'>
                Get 4.83% on instantly available cash in your brokerage account
              </p>
            </div>
            <p className='title-text'>Global Market Access</p>
            <div className='checkmark-content'>
              <img className='checkmark-icon' alt='check' src={CheckMark} />
              <p className='small-text'>
                Get 4.83% on instantly available cash in your brokerage account
              </p>
            </div>
            <p className='title-text'>Professional Pricing</p>
            <div className='checkmark-content'>
              <img className='checkmark-icon' alt='check' src={CheckMark} />
              <p className='small-text'>
                Commissions start at USD 0 on US listed stocks and ETFs with low
                commissions on other products, and there are no added spreads,
                ticket charges or account minimums.3
              </p>
            </div>
            <div className='checkmark-content'>
              <img className='checkmark-icon' alt='check' src={CheckMark} />
              <p className='small-text'>
                Margin rates up to 49% lower than the industry.4
              </p>
            </div>
            <div className='checkmark-content'>
              <img className='checkmark-icon' alt='check' src={CheckMark} />
              <p className='small-text'>
                Earn high interest rates of up to USD 4.83% on instantly
                available cash.5
              </p>
            </div>
            <div className='checkmark-content'>
              <img className='checkmark-icon' alt='check' src={CheckMark} />
              <p className='small-text'>
                BKR BestXTM is a powerful suite of advanced trading technologies
                designed to help clients achieve best execution and maximize
                price improvement while minimizing market impact.
              </p>
            </div>
            <div>
              <p className='title-text'>A Broker You Can Trust</p>
              <p className='small-text'>
                When placing your money with a broker, you need to make sure
                your broker is secure and can endure through good and bad times.
                Our strong capital position, conservative balance sheet and
                automated risk controls are designed to protect IBKR and our
                clients from large trading losses.
              </p>
            </div>
            <div className='stats-wrapped-row'>
              <div className='stat'>
                <p className='title-text'>IBKR</p>
                <p className='small-text'>Nasdaq Listed</p>
              </div>
              <div className='stat'>
                <p className='title-text'>$13.3B</p>
                <p className='small-text'>Equity Capital6</p>
              </div>
              <div className='stat'>
                <p className='title-text'>74.6%</p>
                <p className='small-text'>Privately Held6</p>
              </div>
              <div className='stat'>
                <p className='title-text'>$9.4B</p>
                <p className='small-text'>Excess Regulatory Capital6</p>
              </div>
              <div className='stat'>
                <p className='title-text'>2.43M</p>
                <p className='small-text'>Client Accounts6</p>
              </div>
              <div className='stat'>
                <p className='title-text'>1.91M</p>
                <p className='small-text'>Daily Avg Revenue Trades6</p>
              </div>
            </div>
            <p className='title-text' style={{ alignSelf: 'center' }}>
              Award Winning{' '}
            </p>
            <div className='awards'>
              <div className='award'>
                <img alt='' src={Barron} />
                <p className='small-text'>
                  #1 Best Online Broker 5 out of 5 stars
                </p>
              </div>
              <div className='award'>
                <img alt='' src={Investopedia} />
                <p className='small-text'>4.6 out of 5 stars</p>
              </div>
              <div className='award'>
                <img alt='' src={BrokerChooser} />
                <p className='small-text'>Best Online Broker</p>
              </div>
            </div>
            <button
              className='onboarding-btn'
              onClick={() => handleOnboardingPress()}
            >
              Start Onboarding
            </button>
          </div>
        )
    }
  }

  return (
    <Dialog
      visible={props?.showIbMigrationPopup}
      // style={{ width: props?.isSmallScreen ? '90vw' : '60vw' }}
      closable // no "x" button
      className='IB-form-dialog'
      draggable={false}
      onHide={() => (props?.closeModal ? props?.closeModal() : {})}
      header={() => {
        if (dialogScreen === 'finra') {
          return (
            <p
              onClick={() => setDialogScreen('main')}
              style={{ textAlign: 'start' }}
            >
              Back
            </p>
          )
        }
        return null
      }}
    >
      {renderDialogScreen()}
    </Dialog>
  )
}
